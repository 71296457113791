import { Headline } from "../components/Headline/Headline";
import { ListGrid } from "../components/ListGrid/ListGrid";

export const Garantien = () => {
  return (
    <div>
      <ListGrid />
      <Headline center>Wir tun, was wir können …</Headline>
      <p className="text-center">
      Das Können aller unserer Mitarbeiter wird Sie begeistern, denn deren Kompetenz und Engagement sind spürbar. 
      Zu jeder Zeit, und täglich aufs Neue. Perfekter Service ist der beste, und nach unserer Meinung auch auf Dauer 
      einzige Weg zu erfolgreichem wirtschaftlichen Handeln. Überzeugen Sie sich selbst von der hohen Qualität unserer 
      Callcenter-Dienstleistungen.
      </p>
    <Headline center>… weil wir können, was wir tun!</Headline>
      <p className="text-center">
      Qualität schafft Vorsprung. Mit Herzblut und Leidenschaft realisieren wir sowohl kleine als auch große 
      Callcenter-Aufträge mit zielführender Perfektion. Was ist für Sie und für Ihre Kunden als Anrufer in erster 
      Linie wichtig? Doch sicher, dass Sie verstanden werden. Dass Ihr Anliegen bei Ihrem Gesprächspartner glasklar und 
      eindeutig ankommt.
      </p>
    <Headline center>Wir verstehen Sie – uneingeschränkt</Headline>
      <p className="text-center">
      Als Anrufer aus dem deutschen Sprachraum erwartet man mit Recht einen Gesprächspartner,der perfekt 
      Deutsch versteht und spricht. Wir garantieren das, ohne Wenn und Aber. Und das sind unsere weiteren Garantien 
      für Sie als Kunde:
        <br />
        •1a Sprachvermögen
        <br />
        •Datenschutzkonform
        <br />
        •Telemarketing-Ideen
        <br />
        •Beste Qualifikationen
      </p>
    </div>
  );
};
