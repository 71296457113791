import { CallbackButton } from "../CallbackButton/CallbackButton";
import styles from "./CTAForm.module.css";

export const CTAForm = () => {
  return (
    <div className={styles.wrapper}>
      <form className={styles.container}>
        <CallbackButton text="CALLBACK" autoWidth />
        <input className={styles.input} type="text" placeholder="Name:" />
        <input className={styles.input} type="text" placeholder="Telefon:" />
        <input type="submit" className={styles.submit} value="Senden" />
      </form>
    </div>
  );
};
