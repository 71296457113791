import logoImg from "../../assets/logo.png";
import styles from "./Logo.module.css";

export const Logo = () => {
  return (
    <div className={styles.logoWrapper}>
      <img className={styles.logo} src={logoImg} alt="Bellbeo logo" />
    </div>
  );
};
