import styles from "./MainMenu.module.css";
import { NavLink } from "react-router-dom";

export const MainMenu = ({ path }) => {
  return (
    <div className={styles.mmwrap}>
      <ul className={styles.mmlist}>
        <li>
          <NavLink
            className={
              path === "/"
                ? `${styles.mmlistIemActive}`
                : `${styles.mmlistItem}`
            }
            to="/"
          >
            Startseite
          </NavLink>
        </li>
        <li>
          <NavLink
            className={
              path === "/uber_uns"
                ? `${styles.mmlistIemActive}`
                : `${styles.mmlistItem}`
            }
            to="/uber_uns"
          >
            Über uns
          </NavLink>
        </li>
        <li>
          <NavLink
            className={
              path === "/leistungen"
                ? `${styles.mmlistIemActive}`
                : `${styles.mmlistItem}`
            }
            to="/leistungen"
          >
            Leistungen
          </NavLink>
        </li>
        <li>
          <NavLink
            className={
              path === "/kontakt"
                ? `${styles.mmlistIemActive}`
                : `${styles.mmlistItem}`
            }
            to="/kontakt"
          >
            Kontakt
          </NavLink>
        </li>
      </ul>
    </div>
  );
};
