import styles from "./CallbackButton.module.css";
import { FaChevronRight } from "react-icons/fa";

export const CallbackButton = ({ text, hideIcon = true, className, autoWidth }) => {
  return (
    <div className={styles.cbtn} style={{width: autoWidth ? "auto" : null}}>
      {text}
      {!hideIcon ? (
        <span className={styles.cbtnIcon}>
          <FaChevronRight />
        </span>
      ) : null}
    </div>
  );
};
