import React from "react";
import styles from "./InfoButton.module.css";
import { Link } from "react-router-dom";

export const InfoButton = ({ path = "", right }) => {
  return (
    <div className={right && styles.right}>
      <Link to={path} className={styles.infobtn}>
        Mehr Info...
      </Link>
    </div>
  );
};
