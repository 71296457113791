import { useLocation } from "react-router-dom";
import { DesktopNav, MobileNav } from "./navigation/";
import styles from "./Layout.module.css";

export const Layout = ({ children }) => {
  const location = useLocation();

  return (
    <div>
      <DesktopNav path={location.pathname} />
      <div className={styles.paperWrapper}>
        <div className={styles.paper}>{children}</div>
        <MobileNav path={location.pathname} />
      </div>
    </div>
  );
};
