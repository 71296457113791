import { Headline } from "../components/Headline/Headline";
import { ImageTextOverlay } from "../components/ImageTextOverlay/ImageTextOverlay";
import { ListGrid } from "../components/ListGrid/ListGrid";

export const About = () => {
  const title = "Unsere leistungen";
  const list = ["Profesionelles Team", "Jahrelange Erfahrung", "Datenschutz", "Telefonschulung und Marktforschung"];
  return (
    <div>
      <ListGrid />
      <Headline center>Kein Buch mit sieben Siegeln</Headline>
      <p className="text-center">
        Schweizer Callcenter sind teuer, bei günstigen Anbietern im Ausland
        mangelt es oft an Qualität. Gibt es nicht eine Möglichkeit, mit der sich
        „gut“ und „günstig“ kombinieren lässt? Das haben wir uns bei der
        Gründung unseres Callcenters gefragt – und die optimale Lösung gefunden.
      </p>
      <Headline center>FAKTEN ZUM KONTAKTEN</Headline>
      <p className="text-center">
        Unser Schweizer Management achtet auf die Einhaltung höchster
        Qualitätsstandards. Dazu gehört auch die regelmäßige Schulung der
        Agenten. So können wir Spitzenleistungen durch die neueste
        Verkaufspsychologie gewährleisten. Natürlich sind auch die
        Sprachkenntnisse perfekt. Und das Schönste: Selbst wer jetzt einen
        moderaten Preis erwartet, wird positiv überrascht werden!
      </p>
      <Headline center> Machen Sie gute Erfahrungen</Headline>
      <p className="text-center">
        Mit einer reibungslosen Abwicklung und einer hohen Erfolgsquote sorgen
        wir gerne dafür, dass Sie sich bei uns bestens aufgehoben fühlen. Das
        Vertrauen unserer Kunden zu verdienen, ist erklärtes Ziel und bewährte
        Praxis. Schildern Sie uns Ihren Bedarf. Wir freuen uns auf Sie!
      </p>
      <ImageTextOverlay list={list} title={title} />
      <div className="paddingBot" />
    </div>
  );
};
