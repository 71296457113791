import React from "react";
import divider from "../../assets/divider.png";
import styles from "./Divider.module.css";

export const Divider = () => {
  return (
    <div className={styles.divider}>
      <img src={divider} alt="Bellbeo devider" />
    </div>
  );
};
