import { Headline } from "../components/Headline/Headline";
import { ListGrid } from "../components/ListGrid/ListGrid";

export const Services = () => {
  return (
    <div>
      <ListGrid />
      <Headline center>Grenzenlos & Leistungsstark</Headline>
      <p className="text-center">
        Was gibt es Schöneres, als wenn Sie Ihren Service in die bewährten Hände
        nur eines einzigen Anbieters geben können? Schenken Sie Ihr Vertrauen
        nur einem Partner, der Ihre Anforderungen optimal abdecken kann und für
        Ihren Kunden ein kompetenter Ansprechpartner ist. Wir sind sicher, dass
        unser sensationelles Kompetenzspektrum auch Sie begeistern wird.
      </p>
      <Headline center>Testen Sie uns gern</Headline>
      <p className="text-center">
        Reihen Sie sich ein in die täglich wachsende Gruppe zufriedener Kunden.
        Für Ihre schnelle Kontaktaufnahme nutzen Sie einfach unsere
        kostenlose Beratungs-Line. Das Team Bellbeo beantwortet gerne Ihre
        Fragen und nimmt Ihre Wünsche dankbar entgegen. Denn wir arbeiten mit
        kontinuierlicher Konsequenz daran, unsere guten Leistungen noch besser
        zu machen.
      </p>
      <Headline center>Ein Portfolio das überzeugt</Headline>
      <p className="text-center">
        Das Callcenter Bellbeo bietet Ihnen diesen professionelle
        Telefonservice:
        <br />
        •Inbound für Anfragen und Service
        <br />
        •Outbound für Akquise und Kundenbindung
        <br />
        •Adressqualifizierung und Datenverarbeitung
        <br />
        Denn ein Leistungsangebot überzeugt nur, wenn sich die Interessen
        unserer potenziellen Kunden darin wiederfinden.
      </p>
    </div>
  );
};
