import { Headline } from "../components/Headline/Headline";
import { ListGrid } from "../components/ListGrid/ListGrid";

export const ServicesInfo = () => {
  return (
    <div>
      <ListGrid />
      <Headline center>Starke Leistung</Headline>
      <p className="text-center">
      Ausgezeichnete Sprach- und Leistungskompetenzen sind der Schlüssel für Erfolg und die Basis für 
      jedes erfolgreiche Callcenter. Unser Fachwissen, unser Engagement und der vorbildliche Service unseres
       Unternehmens überzeugt tagtäglich jeden unserer Kunden. Nutzen Sie diese Tatsache zu Ihrem Vorteil!
      </p>
    <Headline center>Starker Service</Headline>
      <p className="text-center">
      Überlassen Sie doch einfach die telefonische Betreuung Ihrer Bestandskunden oder die Neukundengewinnung 
      den qualifizierten Callcenter-Experten aus dem Hause Bellbeo. Selbstverständlich verleihen wir Ihrem Büro 
      auch ein freundliches Gesicht, durch unseren individuellen Sekretariatsservice.
      </p>
    <Headline center>Hört sich gut an: Bellbeo</Headline>
      <p className="text-center">
       Geben Sie sich nur mit perfektem Service zufrieden. Wir wissen, Sie haben professionelle Leistung und
       professionellen Service verdient. Und das alles bieten wir Ihnen:
        <br />
        •nbound plus Outbound Services
        <br />
        •7/365 Sekreatariatsservice
        <br />
        •Konzeptionelle Dienste
        <br />
        Bei Bellbeo bekommen Sie die Leistungen, die Sie sich schon immer gewünscht haben.
      </p>
    </div>
  );
};
