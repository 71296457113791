import React from "react";
import styles from "./MobileNav.module.css"; // FcServices
import { AiOutlinePlus } from "react-icons/ai";
import { NavLink, Link } from "react-router-dom";
import {
  MdHome,
  MdMiscellaneousServices,
  MdPeople,
  MdCall,
} from "react-icons/md";

export const MobileNav = ({ path }) => {
  return (
    <>
      <div className={styles.mnavwrap}>
        <NavLink
          className={
            path === "/" ? `${styles.mnavlinkActive}` : `${styles.mnavlink}`
          }
          to="/"
        >
          <div className={styles.mnavlinkIcon}>
            <MdHome size={20} />
          </div>
          <div className={styles.mnavlinkDesc}>startseite</div>
        </NavLink>
        <NavLink
          className={
            path === "/uber_uns"
              ? `${styles.mnavlinkActive}`
              : `${styles.mnavlink}`
          }
          to="/uber_uns"
        >
          <div className={styles.mnavlinkIcon}>
            <MdPeople size={20} />
          </div>
          <div className={styles.mnavlinkDesc}>über uns</div>
        </NavLink>
        <div>
          <div className={styles.plusWrapper}>
            <Link to="/kontakt#form">
              <AiOutlinePlus size={28} />
            </Link>
          </div>
        </div>
        <NavLink
          className={
            path === "/leistungen"
              ? `${styles.mnavlinkActive}`
              : `${styles.mnavlink}`
          }
          to="/leistungen"
        >
          <div className={styles.mnavlinkIcon}>
            <MdMiscellaneousServices size={20} />
          </div>
          <div className={styles.mnavlinkDesc}>leistungen</div>
        </NavLink>
        <NavLink
          className={
            path === "/kontakt"
              ? `${styles.mnavlinkActive}`
              : `${styles.mnavlink}`
          }
          to="/kontakt"
        >
          <div className={styles.mnavlinkIcon}>
            <MdCall size={20} />
          </div>
          <div className={styles.mnavlinkDesc}>kontakt</div>
        </NavLink>
      </div>
      <div className={styles.bottomBorder}></div>
    </>
  );
};
