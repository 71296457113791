import React from "react";
import styles from "./ContactForm.module.css";
import { CallbackButton } from "../CallbackButton/CallbackButton";

export const ContactForm = () => {
  return (
    <div className={styles.wrapper}>
      <form>
        <div className={styles.formItem}>
          <CallbackButton text={"FIRMA"} />
          <input type="text" />
        </div>
        <div className={styles.formItem}>
          <CallbackButton text={"NAME"} />
          <input type="text" />
        </div>
        <div className={styles.formItem}>
          <CallbackButton text={"TELEFON"} />
          <input type="text" />
        </div>
        <div className={styles.formItem}>
          <CallbackButton text={"EMAIL"} />
          <input type="text" />
        </div>
        <div className={styles.formItem}>
          <CallbackButton text={"BETREFF"} />
          <input type="text" />
        </div>
        <div className={styles.formItem}>
          <CallbackButton text={"NACHRICHT"} />
          <input type="text" />
        </div>
      </form>
    </div>
  );
};
