import { CTAForm } from "../../components/CTAForm/CTAForm";
import { Hero } from "../../components/Hero/Hero";
import { Logo } from "../../components/Logo/Logo";
import { MainMenu } from "../../components/MainMenu/MainMenu";
import styles from "./DesktopNav.module.css";

export const DesktopNav = ({ path }) => {
  return (
    <nav className={styles.dnavWrapper}>
      <Hero />
      <div className={styles.dnavContainer}>
        <MainMenu path={path} />
        <Logo />
        <CTAForm />
      </div>
    </nav>
  );
};
