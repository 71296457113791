import React from "react";
import styles from "./ListGrid.module.css";
import { List } from "../List/List";
import { listData } from "../../pages/listdata";
import { Headline } from "../Headline/Headline";
import { Divider } from "../Divider/Divider";
import { Link, useLocation } from "react-router-dom";

export const ListGrid = () => {
  const { pathname } = useLocation();
  return (
    <>
      <div className={styles.grid4}>
        <div>
        <Headline><Link className={pathname === "/outbound" ? styles.visited : styles.link} to="/outbound">Outbound</Link></Headline>
          <br />
          <List items={listData["list1"]} />
        </div>
        <div>
          <Headline><Link className={pathname === "/servicesinfo" ? styles.visited : styles.link} to="/servicesinfo">Services</Link></Headline>
          <br />
          <List items={listData["list2"]} />
        </div>
        <div>
          <Headline><Link className={pathname === "/garantien" ? styles.visited : styles.link} to="/garantien">Garantien</Link></Headline>
          <br />
          <List items={listData["list3"]} />
        </div>
        <div>
          <Headline><Link className={pathname === "/inbound" ? styles.visited : styles.link} to="/inbound">Inbound</Link></Headline>
          <br />
          <List items={listData["list4"]} />
        </div>
      </div>
      <Divider />
    </>
  );
};
