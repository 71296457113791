// import styles from "Hero.module.css";
import heroImg from "../../assets/heroImg.jpg";
import styles from "./Hero.module.css";

export const Hero = () => {
  return (
    <div className={styles.hero}>
      <img src={heroImg} alt="Bellbeo hero img" />
    </div>
  );
};
