import { Headline } from "../components/Headline/Headline";
import { ListGrid } from "../components/ListGrid/ListGrid";

export const Outbound = () => {
  return (
    <div>
      <ListGrid />
      <Headline center>Individuell und professionell!</Headline>
      <p className="text-center">
      Wir gehen aktiv für Sie auf Ihre Kunden und Interessenten zu. Ob Deutsch, Französisch, Italienisch, 
      Englisch oder andere Sprachen auf Anfrage: Wir nehmen täglich von 8:00 bis 20.15 Uhr Kontakt für Sie auf – 
      mit feinem Fingerspitzengefühl und einer Extraportion Kompetenz! Überlassen Sie uns den (Telefon-)Vertrieb ruhig uns!
      </p>
      <Headline center>Vertrieb und Marktforschung? Das können wir für Sie tun</Headline>
      <p className="text-center">
      Wir werben bei Ihrer Zielgruppe, gewinnen Ihre Kunden zurück, vereinbaren Termine, führen Testanrufe durch, 
      qualifizieren Adressen, fassen bei Mailing-Aktionen nach und sehen uns verantwortlich für Ihr Cross- und Upselling. 
      Auch im Bereich der Marktforschung unterstützen wir Sie gerne: Möchten Sie Kundenbefragungen, Zufriedenheitsumfragen 
      oder Potential- analysen durchführen? Dann profitieren Sie von uns.
      </p>
      <Headline center> Rundum-Zufriedenheit garantiert!</Headline>
      <p className="text-center">
      Unser Service fängt nicht erst beim Telefonieren an, und hört dort auch nicht auf. Das ist unser Leistungsspektrum:
        •Kampagnenplanung
        <br />
        •Erstellung von Statistiken
        <br />
        •Gesprächsleitfäden und -skripte
        <br />
        •Analyse der Gesprächsergebnisse
        <br />
        •Argumentations- und Einwandsbehandlungen
        <br />
        •Versand von Unterlagen, Terminen und Bestätigungen
      Auch die andere Kommunikationskanäle wie Brief, Fax, E-Mail, SMS, Web-Chat und Blog decken wir bei Bedarf für Sie ab. 
      Haben Sie Fragen? Kontaktieren Sie uns gerne!
      </p>
    </div>
  );
};
