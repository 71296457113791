import { Headline } from "../components/Headline/Headline";
import { ListGrid } from "../components/ListGrid/ListGrid";

export const Inbound = () => {
  return (
    <div>
      <ListGrid />
      <Headline center>So behalten Sie den Anschluss.</Headline>
      <p className="text-center">
      Kunden möchten sich gut aufgehoben fühlen. Lange Wartezeiten und eine schleppende Abwicklung bei telefonischen 
      Anfragen machen dementsprechend einen schlechten Eindruck. Wir schaffen Abhilfe. Ob Feuerwehr oder treuer Partner: 
      Entscheiden Sie ganz frei, in welchem Umfang Sie unser internationales Callcenter-Team beauftragen möchten. Wir 
      unterstützen Sie mit unserem Telefonservice täglich von 8:00 bis 18:00 Uhr – permanent oder stundenweise.
      </p>
    <Headline center>Perfekter Service: Unser Responsecenter</Headline>
      <p className="text-center">
      Bestellannahme, Retouren- & Reklamationsbearbeitung, Ticket- & Buchungsservice, Datenerfassung, Debitorenbearbeitung 
      oder Response-Verarbeitung: Das Telefon ist und bleibt der heiße Draht zum Kunden. Vertrauen Sie Ihre Dienste Bellbeo 
      an. So Sie an Aufwand und Kosten – und bekommen dafür eine professionelle und serviceorientierte Abwicklung.
      </p>
    <Headline center>Nur das Beste: Unsere Kundenbetreuung</Headline>
      <p className="text-center">
      Sie suchen einen professionellen Callcenter-Service? Das leisten wir gerne für Sie:
        <br />
        •Auskunftserteilung
        <br />
        •Produktinformation und Beratung
        <br />
        •Allgemeine administrative Arbeiten
        <br />
        •Hot-/Infoline für jegliche Dienstleistungen und Branchen
        <br />
        •Vollständige Kundendienstleistungen
        <br />
        Hierzu zählen Bestell-, Versand-, Retouren- und Reklamationsbearbeitung, Unterlagenversand, 
        Debitorenverarbeitung mit Mahnstufen sowie Telefonservice für KMU’s.
      </p>
    </div>
  );
};
