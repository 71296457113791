export const listData = {
  list1: [
    "Neukundengewinnung",
    "Kundernrückgewinnung",
    "Terminvereinbarungen",
    '"After Sales" Calls',
  ],
  list2: [
    "Adressqualifizierungen",
    "Telefonschulungen",
    "Marktforschung",
    "Datenverarbeitung",
  ],
  list3: [
    "1a Sprachvermögen",
    "Datenschutzkonform",
    "Telemarketing-Ideen",
    "Beste Qualifikationen",
  ],
  list4: [
    "Informationsdienste",
    "Sekreteriat-Service",
    "Bestellannahme",
    "Reklamationshotline",
  ],
};
