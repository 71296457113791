import React from "react";
import styles from "./List.module.css";

export const List = ({ items }) => {
  return (
    <ul className={styles.list}>
      {items.map((item, index) => {
        return <li className={styles.listItem} key={index}>{item}</li>;
      })}
    </ul>
  );
};
