import React from 'react';
import woman from "../../assets/woman.png";
import { Headline } from '../Headline/Headline';
import { List } from '../List/List';
import styles from "./ImageTextOverlay.module.css";

export const ImageTextOverlay = ({ title, list, alt }) => {
    return (
        <div className={styles.container}>
            <img src={woman} alt={alt} />
            <div className={styles.textWrap}>
                <Headline>{title}</Headline>
                <List items={list} />
            </div>
        </div>
    )
}
