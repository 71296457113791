import { ContactForm } from "../components/ContactForm/ContactForm";
import { Headline } from "../components/Headline/Headline";
import { ListGrid } from "../components/ListGrid/ListGrid";

export const Contact = () => {
  return (
    <div>
      <ListGrid />
      <Headline id="form" center>FAKTEN ZUM KONTAKTEN</Headline>
      <ContactForm />
    </div>
  );
};
