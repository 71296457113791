import { Headline } from "../components/Headline/Headline";
import { InfoButton } from "../components/InfoButton/InfoButton";
import img1 from "../assets/home1.png";
import styles from "./Homepage.module.css";
import { Divider } from "../components/Divider/Divider";
import { List } from "../components/List/List";
import { listData } from "./listdata";

export const Home = () => {
  return (
    <div>
      <div className="row-grid">
        <div className={styles.gridItem}>
          <Headline>Outbound</Headline>
          <List items={listData["list1"]} />
          <InfoButton path="/outbound" />
        </div>
        <div className="home-img">
          <img src={img1} alt="Bellbeo 1" />
        </div>
        <div className={styles.gridItem}>
          <Headline>Services</Headline>
          <List items={listData["list2"]} />
          <InfoButton right path="/servicesinfo" />
        </div>
      </div>
      <Divider />
      <div className="row-grid">
        <div className={styles.gridItem}>
          <Headline>Garantien</Headline>
          <List items={listData["list3"]} />
          <InfoButton path="/garantien" />
        </div>
        <div className="home-img">
          <img src={img1} alt="Bellbeo 1" />
        </div>
        <div className={styles.gridItem}>
          <Headline>Inbound</Headline>
          <List items={listData["list4"]} />
          <InfoButton right path="/inbound"/>
        </div>
      </div>
      <Divider />
      <Headline center>Bellbeo – für den besten Eindruck</Headline>
      <p className="text-center">
        Perfekte Sprache, Top-Service, höchste Kompetenz und günstige
        Konditionen: All das vereinen wir bei Bellbeo, Ihrem internationalen
        Callcenter für alle In- & Outbound-Anliegen. Unsere Mitarbeiter sprechen
        selbstverständlich perfektes Hochdeutsch. Aber auch die Kommunikation
        auf Englisch, Italienisch, Französisch und in vielen anderen Sprachen
        lassen an Kenntnis und Sprachgewandtheit, Qualität und Zuverlässigkeit
        nichts zu wünschen übrig.
      </p>
      <Headline center>Ihre Anforderungen? Unser Callcenter!</Headline>
      <p className="text-center">
        Unsere professionelle Gesprächsführung macht den hörbaren Unterschied
        für Ihre Kunden. Unseren internationalen Telefonservice bieten wir Ihnen
        zu fairen, günstigen Konditionen an. Überzeugen Sie sich selbst!
        Verschaffen Sie sich gerne einen bleibenden Eindruck von unserem
        professionellen Callcenter-Service. In einer ausführlichen Beratung
        erfahren Sie alles über unsere Leistungen. Rufen Sie uns gerne an! Wir
        freuen uns auf Sie und Ihr Projekt!
      </p>
    </div>
  );
};
