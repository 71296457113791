import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Home } from "./pages/Homepage";
import { About } from "./pages/Aboutpage";
import { Services } from "./pages/Servicespage";
import { Contact } from "./pages/Contactpage";
import { Layout } from "./layout/Layout";
import { Outbound } from "./pages/Outboundpage";
import { ServicesInfo } from "./pages/ServicesInfopage";
import { Garantien } from "./pages/Garantienpage";
import { Inbound } from "./pages/Inboundpage";

export default function App() {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route exact path="uber_uns" element={<About />} />
          <Route exact path="leistungen" element={<Services />} />
          <Route exact path="kontakt" element={<Contact />} />
          <Route exact path="outbound" element={<Outbound />} />
          <Route exact path="servicesinfo" element={<ServicesInfo />} />
          <Route exact path="garantien" element={<Garantien />} />
          <Route exact path="inbound" element={<Inbound />} />
        </Routes>
      </Layout>
    </Router>
  );
}
